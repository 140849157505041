.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999;
  width: 100vw;
  height: 100dvh;
  background-color: #00000050;
}

.modal-content {
  margin: 0 auto;
  z-index: 99999999999999999;
  position: fixed;
  top: var(--top, 20%);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
  width: calc(100vw - 2rem);
  max-width: var(--max-width, 50rem);
  padding: var(--padding, 1.5rem);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: var(--max-height, calc(100dvh - 4rem));
  overflow: scroll;
  box-shadow: 0px 0px 16px 0px #4cbfa780 inset;
  box-shadow: 0px 4px 16px 0px #4cbfa7 inset;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e9ecef;
}

.modal-heading {
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

@media (min-width: 768px) {
  .modal-heading {
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
  }
}

@media (min-width: 1280px) {
  .modal-heading {
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
  }
}

.modal-icon {
  align-self: flex-end;
  cursor: pointer;
}
.modal-icon:hover {
  opacity: 0.8;
}

.modal-icon img {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}
