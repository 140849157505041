.right-single-bar {
  cursor: pointer;
  width: 100%;
  height: 56px;
  background-color: #e9f6f3;
  border-radius: 7px 7px 0px 0px;
  padding: 8px 12px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 0.7rem; */
}
.right-single-bar-element {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.right-single-bar-row-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Inter';
  color: #131515;
  margin-right: 8px;
}
.right-single-bar-row-value {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Inter';
  color: #131515;
  text-overflow: ellipsis;
}

.left-vertical-bar {
  width: 11%;
  height: auto;
  background-color: #e9f6f3;
  margin-right: 18px;
  border-radius: 8px, 0px, 0px, 8px;
  padding: 12px, 7px, 12px, 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right-main-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.left-vertical-bar-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.left-vertical-bar-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16.94px;
  color: #202020;
  margin-top: 8px;
}

.inner-single-bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner-single-bar {
  width: 97%;
  height: max-content;
  background-color: #e9ebed;
  border-radius: 7px 7px 0px 0px;
  padding: 8px 12px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
  margin-top: 0.6rem;
}

.inner-single-bar-row-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #979c9e;
}
.inner-single-bar-row-value {
  color: #131515;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.inner-single-bar-row-drop-icon {
  display: flex;

  align-items: center;
  cursor: pointer;
}

.inner-single-bar-row-drop-icon:hover {
  scale: 1.2;
}

.detailedProgressTable-left-aligned {
  width: 30%;
  height: 42px;
  border-radius: 5.74px;
  /* border: 1px solid #979c9e; */
  padding: 9.57px 11.48px 9.57px 11.48px;
}

.right-aligned-search-input {
  height: 45px;
  width: 100%;
  border-radius: 5.74px;
  border: 0.96px solid #dcdcdc;
  padding: 9.57px 11.48px 9.57px 11.48px;
  background-color: #f9f9f9;
  border-color: #dcdcdc;
  font-size: 14px;
  line-height: 21px;
}

.right-aligned-search-input:focus {
  outline: none;
  border-color: #e9f6f3;
  box-shadow: 0px 0px 0px 1px #dcdcdc;
}
