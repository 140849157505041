.class_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.count_badge {
  background-color: #20a58a;
  color: #ffff;
  border-radius: '3px';
  padding: 2px 4px;
  text-align: center;
  border-radius: 2px;

  right: 0px;
  bottom: 8px;
}

.badge_head {
  position: relative;
  margin-left: 10px;

  margin-top: 60%;
  height: 40px;
}

.status_button {
  background: #ddfaec;
}
