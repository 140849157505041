/* @import 'rsuite/styles/index.less';  */
/* @import '~rsuite/styles/index.less';
@import './rsuite/styles/index.less'; */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.abdd {
  background: linear-gradient(rgb(228, 228, 228) 10%, #ffffff 100%);
}
.ab {
  background: linear-gradient(rgb(232, 218, 167) 10%, #ffffff 100%);
}
.ad {
  background: linear-gradient(rgb(175, 170, 160) 10%, #ffffff 100%);
}
