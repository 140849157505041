.action_popup {
  z-index: 99999;
  position: absolute;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  width: 140px;
  padding: 14px;
  right: 50px;
}
.action_item {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  padding: 10px 0px;
  text-align: left;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}
.action_item:hover {
  color: #20a58a;
  cursor: pointer;
}
