.profile_detail_wrapper {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: space-between;
}

.profile_detail {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .profile_detail {
    flex-direction: column;
    align-items: start;
  }
  .info {
    flex-direction: column;
  }
  .content_wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.profile_img {
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  align-items: center;
  border-radius: 8px;
}

.info {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.content_wrapper {
  display: flex;
  gap: 20px;
}

.classroom_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ellipse_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hello > input[readonly] {
  cursor: text;
  background-color: red;
}
