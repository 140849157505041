.react-dates__header {
  display: none !important;
}

/* Or hide specific parts of the header */
.react-dates__navigation--previous {
  display: none !important;
}

.react-dates__navigation--next {
  display: none !important;
}
