.selected {
  border: 3px solid #c73e9d;
  border-radius: 100px;
}

.arrow {
  position: absolute;
  bottom: 0px;
  right: 30px;
}
